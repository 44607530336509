const API_KEY = import.meta.env.VITE_YOUTUBE_API_KEY

const getYouTubeVideo = async videoId => {
  const url = `https://www.googleapis.com/youtube/v3/videos?part=snippet,statistics&id=${videoId}&key=${API_KEY}`

  try {
    const response = await fetch(url)
    const data = await response.json()
    const video = {
      id: data.items[0].id,
      title: data.items[0].snippet.title,
      description: data.items[0].snippet.description,
      thumbnailUrl: data.items[0].snippet.thumbnails.default.url,
      channelTitle: data.items[0].snippet.channelTitle,
      publishedAt: data.items[0].snippet.publishedAt,
      viewCount: data.items[0].statistics.viewCount,
      likeCount: data.items[0].statistics.likeCount,
      dislikeCount: data.items[0].statistics.dislikeCount,
      tags: data.items[0].snippet.tags,
    }

    return video
  } catch (error) {
    console.error('Error fetching video data:', error)
  }
}

export { getYouTubeVideo }
