import { createSlice } from '@reduxjs/toolkit'
import { collection, getDocs, query, where } from 'firebase/firestore'

import { db, myFunctions } from '../firebase'
import { getCurrentUserSubscriptions } from '../utils/stripe-tools'

import { PERMISSIONS_MAP, planTypes } from '@/constants/plans/type-plans'

export const permissionsSlice = createSlice({
  name: 'permissions',
  initialState: {
    loaded: false,
    loading: false,
    permissions: [],
    plan: null,
  },
  reducers: {
    loadingPermissions: (state, action) => {
      state.loaded = false
      state.loading = true
    },
    setPermissions: (state, action) => {
      state = { ...state, ...action.payload, loaded: true, loading: false }

      return state
    },
    clearPermissions: (state, action) => {
      state.loaded = false
      state.loading = false
      state.permissions = []
    },
  },
})

// Action creators are generated for each case reducer function
export const { setPermissions, clearPermissions, loadingPermissions } = permissionsSlice.actions

export default permissionsSlice.reducer

/**
 * 1. Check institution Permissions
 * 2. Check if the user has done a free trial
 * 3. Check stripe permissions
 */

export const loadTeacherPermissions =
  ({ custom_claims }) =>
  async (dispatch, getState) => {
    // 1. Check institution Permissions
    const premissionsQuery = query(
      collection(db, 'institution_subscriptions'),
      where('users', 'array-contains', custom_claims.email),
      where('active', '==', true),
    )

    try {
      const premissionsSnapshot = await getDocs(premissionsQuery)

      if (!premissionsSnapshot.empty) {
        const permissions = premissionsSnapshot?.docs?.map(doc => {
          return {
            id: doc.id,
            ...doc.data(),
          }
        })?.[0]

        if (permissions?.plan) {
          let plan = permissions.plan

          // convert deprecated plans to new plans
          if (plan === planTypes.starter) {
            plan = planTypes.teacher_pro
          } else if (plan === planTypes.professional || plan === planTypes.growth) {
            plan = planTypes.organization
          }

          if (permissions?.hasStudentPortfolios) {
          }

          dispatch(
            setPermissions({
              permissions: PERMISSIONS_MAP[plan],
              plan,
              subscriptionId: permissions?.id,
              isInstitutionPlan: true,
              type: 'institution',
              contact: permissions?.contact || null,
              hasStudentPortfolios: permissions?.hasStudentPortfolios || null,
            }),
          )

          return
        }
      } else {
        console.log('No institution permissions')
      }
    } catch (error) {
      console.log(error)
    }

    // 3. Check stripe permissions
    try {
      const subscriptions = await getCurrentUserSubscriptions()
      const subscription = subscriptions[0]

      if (subscription) {
        let plan = subscription.role

        // convert deprecated plans to new plans
        if (plan === planTypes.starter) {
          plan = planTypes.teacher_pro
        } else if (plan === planTypes.professional || plan === planTypes.growth) {
          plan = planTypes.organization
        }
        dispatch(
          setPermissions({
            permissions: PERMISSIONS_MAP[plan],
            plan,
            isStripePlan: true,
            type: 'stripe',
            subscriptionId: subscription.id,
          }),
        )

        return
      } else {
        console.log('No stripe permissions')
      }
    } catch (error) {
      console.log(error)
    }

    dispatch(setPermissions({ permissions: [], plan: 'basic' }))
  }

/**
 *
 * @param {*} teacherID
 * @returns
 *
 * This function is used to load the permissions of a student
 *
 *  The function needs to be moved to the cloud functions
 *  so that the studet is able to read the teacher's permissions
 *
 */
export const checkStudentPermissions = teacherID => async (dispatch, getState) => {
  try {
    const { data } = await myFunctions.checkStudentPermissions({
      teacherId: teacherID,
    })

    if (data?.error) {
      console.log(data)

      return
    }

    const permissionsObject = {
      permissions: PERMISSIONS_MAP[data?.plan] ?? [],
      plan: data?.plan,
      type: data?.type,
    }

    if (data?.type === 'institution') {
      permissionsObject.isInstitutionPlan = true
      permissionsObject.subscriptionId = data?.subscriptionId
      permissionsObject.orgId = data?.data?.institutionId
    }

    if (data?.type === 'stripe') {
      permissionsObject.subscriptionId = data?.subscriptionId
      permissionsObject.isStripePlan = true
    }

    console.log('permissions loaded', permissionsObject)
    dispatch(setPermissions(permissionsObject))
  } catch (error) {
    console.log('FAILED TO LOAD STUDENT PERMISSIONS', error)
  }
}

export const consumeProPlanCredit = teacherId => async (dispatch, getState) => {
  const { plan, type, subscriptionId = null } = getState().permissions

  if (type === 'stripe' && plan === 'professional' && teacherId) {
    try {
      const { data } = await myFunctions.consumeProPlanCredit({
        uid: teacherId,
      })

      if (data.success && data.credits) {
        dispatch(
          setPermissions({
            credits: data.credits,
          }),
        )
      }
    } catch (error) {
      console.log(error)
    }
  } else if (type === 'institution' && plan === 'professional') {
    try {
      if (!subscriptionId) {
        return
      }
      const { data } = await myFunctions.consumeInstitutionCredit({
        subscriptionId,
      })

      if (data.success && data.remainingCredits) {
        dispatch(
          setPermissions({
            credits: data?.remainingCredits,
          }),
        )
      }
    } catch (error) {
      console.log(error)
    }
  }
}

export const updateAvialableCredits = credits => async (dispatch, getState) => {
  dispatch(
    setPermissions({
      credits,
    }),
  )
}
