/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit'
import { doc, serverTimestamp, setDoc, getDoc } from 'firebase/firestore'
import { ref, uploadBytes } from 'firebase/storage'

import { db, myFunctions, storage } from '../firebase'
import downloadImageFromURL from '../utils/getImageFileFromURL'

import { retrieveId } from '@/utils/getOrgById'

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    profile: {
      isEmpty: true,
    },
    auth: {
      isEmpty: true,
    },
    authLoaded: false,
    profileLoaded: false,
    roles: [],
    loadingRoles: true,
    drawerOpen: true,
    mobileOpen: false,
  },
  reducers: {
    setUserProfile: (state, action) => {
      state = {
        ...state,
        profile: {
          ...action.payload,
          loaded: true,
          loading: false,
        },
        profileLoaded: true,
      }
      if (action.payload?.roles?.includes('ADMIN')) {
        state.profile.isTeacher = true
      } else if (action.payload?.roles?.includes('USER')) {
        state.profile.isStudent = true
      }

      return state
    },
    setUserAuth: (state, action) => {
      state = {
        ...state,
        auth: {
          ...action.payload,
          loaded: true,
          loading: false,
        },
        authLoaded: true,
      }

      return state
    },
    setLTIState: (state, action) => {
      state.lti = action.payload
    },
    clearUser: (state, action) => {
      state.loaded = false
      state.loading = false
      state.profile = {
        isEmpty: true,
      }
      state.auth = {
        isEmpty: true,
      }
    },
    setUserType: (state, action) => {
      state.profile.isTeacher = action.payload === 'teacher'
      state.profile.isStudent = action.payload === 'student'
    },
    toggleDrawer: (state, action) => {
      state.drawerOpen = !state.drawerOpen
      state.mobileOpen = !state.mobileOpen
    },
    drawerOpen: (state, action) => {
      state.drawerOpen = true
      state.mobileOpen = true
    },
    drawerClosed: (state, action) => {
      state.drawerOpen = false
      state.mobileOpen = false
    },
    setRoles: (state, action) => {
      state.roles = action.payload
      state.loadingRoles = false
    },
    clearRoles: state => {
      state.roles = []
      state.loadingRoles = false
    },
    setLoadingRoles: (state, action) => {
      state.loadingRoles = action.payload
    },
  },
})

export const {
  setUserProfile,
  setUserAuth,
  clearUser,
  loadingUser,
  setUserType,
  toggleDrawer,
  drawerClosed,
  drawerOpen,
  setLTIState,
  setRoles,
  clearRoles,
  setLoadingRoles,
} = userSlice.actions

export default userSlice.reducer

export const createNewUserProfile = (profile, other) => {
  return async (dispatch, getState) => {
    try {
      const {
        user: { auth },
      } = getState()
      const { uid, custom_claims = null } = auth

      if (custom_claims?.picture) {
        const file = await downloadImageFromURL(custom_claims?.picture)

        if (file) {
          const storageRef = ref(storage, `profiles/${uid}`)

          await uploadBytes(storageRef, file)
        }
      }
      // filter through the profile object and switch undefined values to ""
      Object.keys(profile).forEach(key => (profile[key] === undefined ? (profile[key] = '') : profile[key]))

      const user = {
        ...profile,
        dateMade: serverTimestamp(),
        owners: [uid],
        speakableio: true,
      }

      const userRef = doc(db, 'users', uid)

      await setDoc(userRef, user, { merge: true })
      dispatch(setUserProfile({ ...user, isEmpty: false, id: uid, uid }))

      return { success: true }
    } catch (error) {
      console.log(error)

      return { error: true, message: error.message }
    }
  }
}

export const updateUserProfile = update => {
  return async (dispatch, getState) => {
    try {
      const {
        user: { auth, profile },
      } = getState()
      const { uid } = auth
      const userRef = doc(db, 'users', uid)

      await setDoc(userRef, update, { merge: true })
      const updatedDoc = await getDoc(userRef)

      dispatch(setUserProfile({ ...profile, ...updatedDoc.data() }))

      return { success: true }
    } catch (error) {
      console.log(error)

      return { error: true, message: error.message }
    }
  }
}

export const handleSwitchUserType = type => {
  return (dispatch, getState) => {
    dispatch(setUserType(type))
  }
}

export const handleAddUserToIntercomCompany = async (email, school) => {
  if (!email || !school?.schoolid) return

  const options = {
    email: email,
    companyData: {
      company_id: school?.schoolid,
      name: school?.schoolName,
      custom_attributes: {},
    },
  }

  if (school?.schoolid) {
    options.companyData.custom_attributes.schoolid = school?.schoolid
  }
  if (school?.city) {
    options.companyData.custom_attributes.city = school?.city
  }
  if (school?.state) {
    options.companyData.custom_attributes.state = school?.state
  }
  if (school?.rank) {
    options.companyData.custom_attributes.rank = school?.rank
  }
  try {
    const result = await myFunctions.addUserToIntercomCompany(options)

    return result
  } catch (error) {
    throw new Error(error)
  }
}

export const fetchUserRoles = ({ email, pathname }) => {
  return async (dispatch, getState) => {
    dispatch(setLoadingRoles(true))
    try {
      const roles = []
      const pathSegments = pathname.split('/')
      const districtIndex = pathSegments.indexOf('district') + 1
      const schoolIndex = pathSegments.indexOf('school') + 1
      let orgId = pathSegments[districtIndex] || null
      const schoolId = pathSegments[schoolIndex] || null
      const formattedOrgId = retrieveId(orgId)
      const formattedSchoolId = retrieveId(schoolId)

      const state = getState()
      let schools = state.organization?.schools || []

      if (schoolId && !orgId) {
        const school = schools.find(s => s.id === formattedSchoolId)

        if (school) {
          orgId = school.institutionId
        } else {
          const userDomain = email.split('@')[1]
          const institutionRef = doc(db, 'institutions', userDomain)
          const institutionDoc = await getDoc(institutionRef)

          if (institutionDoc.exists()) {
            orgId = userDomain
          } else {
            dispatch(setRoles([]))
            dispatch(setLoadingRoles(false))

            return
          }
        }
      }

      if (!orgId && schoolId) {
        dispatch(setRoles([]))
        dispatch(setLoadingRoles(false))

        return
      }

      if (schoolId) {
        const schoolRef = doc(db, 'institutions', orgId, 'schools', formattedSchoolId)
        const schoolDoc = await getDoc(schoolRef)

        if (schoolDoc.exists()) {
          const schoolData = schoolDoc.data()

          if (schoolData.admins?.includes(email)) roles.push('schoolAdmin')
          if (schoolData.managers?.includes(email)) roles.push('schoolManager')
        }
      } else if (orgId && !schoolId) {
        const institutionRef = doc(db, 'institutions', formattedOrgId)
        const institutionDoc = await getDoc(institutionRef)

        if (institutionDoc.exists()) {
          const institutionData = institutionDoc.data()

          if (institutionData.admins?.includes(email)) roles.push('institutionAdmin')
          if (institutionData.managers?.includes(email)) roles.push('institutionManager')
        }
      }

      dispatch(setRoles(roles))
    } catch (error) {
      console.error('Error fetching roles:', error)
      dispatch(setRoles([]))
    } finally {
      dispatch(setLoadingRoles(false))
    }
  }
}
