import React, { useEffect } from "react";
import { db, myFunctions } from "../firebase";
import usePollyTextToSpeech, {
	getPhoneticPronunciation,
	usePollyVoices,
} from "../utils/amazonPollyTextToSpeech";
import langs from "../assets/langs.json";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { getWordHash } from "../utils/getWordHash";
import cleanString from "../utils/clean-string";

let timeout = null;
export const useSyllableBreaking = (word, lang) => {
	const [syllables, setSyllables] = React.useState([]);
	const [loading, setLoading] = React.useState(false);
	const [selectedVoice, setSelectedVoice] = React.useState(null);
	const { langVoices: voices } = usePollyVoices(lang);
	const { pollyTextToSpeech } = usePollyTextToSpeech(word);
	useEffect(() => {
		if (word && lang) {
			clearTimeout(timeout);
			timeout = setTimeout(() => {
				loadSyllables();
			}, 1000);
		}
	}, [word]);

	useEffect(() => {
		if (voices?.length) {
			// if lang = en, get first en-US voice
			if (lang === "en") {
				const enUSVoice = voices.find(
					(voice) => voice.LanguageCode === "en-US"
				);
				setSelectedVoice(enUSVoice);
			} else {
				setSelectedVoice(voices[0]);
			}
		}
	}, [voices]);

	const loadSyllables = async (regenerate = false) => {
		setLoading(true);
		// check the checked-pronunciations collection for the word hash
		const hash = getWordHash(word, lang);
		const docRef = doc(db, "checked-pronunciations", hash);
		if (!regenerate) {
			const docSnap = await getDoc(docRef);
			if (docSnap.exists()) {
				const docData = docSnap.data();
				if (docData?.syllables?.length) {
					setSyllables(docData.syllables);
					setLoading(false);
					return;
				}
			}
		}
		// generate syllables
		const { data } = await myFunctions.breakSyllables({ word, lang });
		if (data?.error) {
			setLoading(false);
			return;
		}
		setSyllables(data?.syllables);

		await setDoc(
			docRef,
			{
				syllables: data?.syllables || [],
				id: `${lang}-${cleanString(word)}`,
				word: word,
				language: lang,
				words: [word],
			},
			{ merge: true }
		);
		setLoading(false);
	};

	const refreshSyllables = async () => {
		await loadSyllables(true);
	};
	const playSyllable = async (ipa) => {
		getPhoneticPronunciation(
			ipa,
			selectedVoice?.Id,
			selectedVoice?.LanguageCode
		);
	};

	const playWord = async () => {
		pollyTextToSpeech({
			speechText: word,
			lang: selectedVoice?.LanguageCode,
			voice: selectedVoice?.Id,
		});
	};

	const fixSyllables = async () => {
		setLoading(true);
		const fixedArray = await regenerateFromWrongIPA(syllables, lang);
		setSyllables(fixedArray);

		// update the checked-pronunciations collection
		const hash = getWordHash(word, lang);
		const docRef = doc(db, "checked-pronunciations", hash);
		await setDoc(
			docRef,
			{
				syllables: fixedArray,
			},
			{ merge: true }
		);

		setLoading(false);
	};

	return {
		syllables,
		playSyllable,
		playWord,
		selectedVoice,
		loadSyllables,
		refreshSyllables,
		fixSyllables,
		loading,
	};
};

const regenerateFromWrongIPA = async (wrongArray, lang) => {
	const value = `You will recieve a word in ${
		lang === "en" ? "American" + langs[lang] : langs[lang]
	} broken into an array with the syllables and ipa pronunciations. There is at least one mistake in the ipa pronunciations. Return the correct ipa pronunciation for each syllable in a JSON formatted array. You may add more syllables if needed if they are missing. The IPA must match the correct pronunciation of the syllable in the context of the full word.
	INCORRECT ARRAY: """${JSON.stringify(wrongArray)}"""
	YOUR JSON FORMATTE FIXED ARRAY RESPONSE in ${
		lang === "en" ? `American ${langs[lang]}` : langs[lang]
	}:
	`;

	try {
		const { data } = await myFunctions.getFreeResponseCompletion({
			messages: [
				{
					role: "user",
					content: value,
				},
			],
		});
		return JSON.parse(data?.response?.content || `{}`);
	} catch (error) {
		console.log(error);
	}
};
